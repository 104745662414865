export enum Permissions {
  AdminDashboard = 'access:admin-dashboard',
  AdminPanel = 'access:admin-panel',
  Chat = 'access:chat',
  Frontend = 'access:clinician-frontend',
  HandTherapy = 'access:hand-therapy',
  PDFLookup = 'access:pdf-lookup',
  PatientHub = 'access:patient-hub',
  Prospectus = 'access:prospectus',
}

export type Permission =
  | Permissions.Frontend
  | Permissions.HandTherapy
  | Permissions.Chat
  | Permissions.AdminPanel
  | Permissions.AdminDashboard
  | Permissions.PDFLookup
  | Permissions.PatientHub
  | Permissions.Prospectus
