import { Checkbox as HCheckbox } from '@headlessui/react'
import styled, { useTheme } from 'styled-components'
import { MdCheck } from 'react-icons/md'

const StyledCheckbox = styled(HCheckbox)`
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.common.black20};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${({ theme }) => theme.colors.common.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.common.black10};
  }

  svg {
    width: 14px;
    height: 14px;
  }

  &[data-checked] {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
`

export const Checkbox = ({
  checked,
  onChange,
}: {
  checked: boolean
  onChange: () => void
}) => {
  const theme = useTheme()
  return (
    <StyledCheckbox checked={checked} onChange={onChange}>
      {checked && <MdCheck color={theme.colors.primary.text} />}
    </StyledCheckbox>
  )
}
