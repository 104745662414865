import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClose } from 'react-icons/ai'
import styled from 'styled-components'

import Send from '@/assets/svgs/send.svg'
import { useSocket } from '@/contexts/useSocketContext'
import { itemsToArray } from '@/util/array'

import { TextArea } from '../../common/TextArea.tsx'

const MainWrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row: 3;
  grid-column: 2;
  background: ${(props) => props.theme.colors.common.white};
  border-right: 1px solid ${(props) => props.theme.colors.common.border};
`

const ComposeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconButton = styled.button`
  font-size: inherit;
  background-color: ${(props) => props.theme.colors.common.white};
  border: none;
  padding-left: 24px;
  cursor: pointer;
  flex-grow: 0;
`

const CloseIconWrapper = styled.button`
  position: absolute;
  top: -15px;
  right: 5px;
  width: 35px;
  height: 35px;
  background: ${(props) => props.theme.colors.common.closeButtonBackground};
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.colors.common.accentDark};
  }

  svg {
    width: 60%;
    height: 60%;
  }
`

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 16px;
  border-radius: 16px;
`

const PreviewWrapper = styled.div`
  display: flex;
  &:empty {
    display: none;
  }
`

function PreviewImage({
  onClick,
  src,
}: {
  onClick: () => void
  src: Blob | MediaSource
}) {
  return (
    <div style={{ position: 'relative' }}>
      <CloseIconWrapper onClick={onClick}>
        <AiOutlineClose />
      </CloseIconWrapper>
      <StyledImage src={URL.createObjectURL(src)} />
    </div>
  )
}

export function ComposeMessage() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chat',
  })
  const [value, setValue] = React.useState('')
  const [files, setFiles] = React.useState<File[]>([])
  const { addMessageToActiveConversation, throttledSendTypingIndicator } =
    useSocket()
  const [rows, setRows] = React.useState(1)

  function removeFile(index: number) {
    setFiles((old) => old.filter((_item, i) => i !== index))
  }

  const preview = React.useMemo(
    () =>
      files
        ? files.map((f, i) => (
            <PreviewImage key={f.name} onClick={() => removeFile(i)} src={f} />
          ))
        : [],
    [files],
  )

  function handleOnChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    e.preventDefault()
    setValue(e.target.value)
    throttledSendTypingIndicator()

    const maxRows = 10
    const textareaLineHeight = 20
    e.target.rows = 1 // reset rows so we can get the proper scroll height
    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight)
    const newRows = Math.min(Math.max(currentRows, 1), maxRows)
    setRows(newRows)
    e.target.rows = newRows
  }

  function doSendMessage() {
    const trimmed = value.trim()
    if (trimmed.length > 0 || files.length > 0) {
      addMessageToActiveConversation(value.trim(), files)
    }
    setValue('')
    setFiles([])
  }

  function sendMessage(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): boolean {
    e.preventDefault()
    doSendMessage()
    return false
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter') {
      e.preventDefault()
      doSendMessage()
    }
  }

  function handleOnChangeFiles(e: React.ChangeEvent<HTMLInputElement>) {
    setFiles(itemsToArray(e.target.files))
  }

  return (
    <MainWrapper data-testid="messagingField">
      <PreviewWrapper>{preview}</PreviewWrapper>
      <ComposeWrapper>
        <TextArea
          onChange={handleOnChange}
          onChangeFiles={handleOnChangeFiles}
          onKeyDown={handleKeyDown}
          placeholder={t('inputPlaceholder')}
          rows={rows}
          value={value}
        />

        <IconButton data-testid="sendMessage" onClick={sendMessage}>
          <img alt="send" src={Send} />
        </IconButton>
      </ComposeWrapper>
    </MainWrapper>
  )
}
